import { useEffect, useState } from "react";

import { verifyPartnerAccount } from "api";
import { decode as base64_decode } from "base-64";
import { LoginEmailForm, LoginMethodForm, LoginPasswordForm } from "components";
import { Alert } from "flowbite-react";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { HiCheckCircle } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { AuthService, clearUrlParamsState } from "services";

export function Login() {
  const [loginStep, setLoginStep] = useState("email"); // email, method, password
  const [infoMsg, setInfoMsg] = useState("");
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [showClaimSuccess, setShowClaimSuccess] = useState(false);
  const [alertsTranslateY, setAlertsTranslateY] = useState();
  const [email, setEmail] = useState();
  const [initialEmailLookup, setInitialEmailLookup] = useState(null);
  const [loginMethods, setLoginMethods] = useState([]);
  const { setLoading } = useLoadingState();

  const location = useLocation();

  /**
   * Ensure there is no logged in user session
   */
  useEffect(() => {
    (async () => {
      setLoading(true);
      await AuthService.ensureNoAuth();
      setLoading(false);
    })();
  }, []);

  const handleLogout = () => {
    setInfoMsg("You have successfully signed out!");
  };

  const handleRemoval = () => {
    setInfoMsg("Your user account has been removed successfully!");
  };

  const handleResetSucess = () => {
    setShowResetSuccess(true);
  };

  const handleClaimSuccess = () => {
    setShowClaimSuccess(true);
  };

  const handleDismissInfoAlert = () => {
    setInfoMsg("");
  };

  const handleDismissResetSucess = () => {
    setShowResetSuccess(false);
  };

  const handleDismissClaimSuccess = () => {
    setShowClaimSuccess(false);
  };

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (!!params?.login && params.login === "Google") {
      AuthService.signinWithGoogle();
    }
    if (!!params?.c) {
      try {
        const cParams = JSON.parse(base64_decode(params?.c));
        if (cParams?.claimed_success === true) {
          handleClaimSuccess();
        } else if (cParams?.reset_success === true) {
          handleResetSucess();
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Check if this was a recent logout
    if (!!params.logout) {
      handleLogout();
    }

    // Check if this was a recent account removal
    if (!!params.removal) {
      handleRemoval();
    }

    if (!!params.email) {
      setEmail(email);
      setInitialEmailLookup(email);
    }

    clearUrlParamsState();
  }, [location.search]);

  useEffect(() => {
    const updateAlertPos = () => {
      const mainContentHeight =
        document.querySelector(".main-content")?.clientHeight || 0;
      const formContainerHeight =
        document.querySelector(".form-container")?.clientHeight || 0;

      // Account for padding (160px), navbar height (75px) and extra space created in main content
      const translateY =
        (mainContentHeight - formContainerHeight) / 4 - (160 - 75) / 2;

      setAlertsTranslateY(translateY);
    };
    updateAlertPos();

    window.addEventListener("resize", updateAlertPos);

    return () => {
      window.removeEventListener("resize", updateAlertPos);
    };
  }, []);

  const lookupLoginEmail = async (e = email) => {
    setInitialEmailLookup(null);
    let lookup = await verifyPartnerAccount({
      email: e,
    });
    if (!lookup?.loginMethods?.length > 0) {
      return {
        err: "No account found.",
      };
    } else {
      setLoginMethods(lookup.loginMethods);
      setLoginStep("method");
    }
  };

  const selectLoginMethod = async (method) => {
    if (method === "email") {
      setLoginStep("password");
    }
  };

  const resetLoginStep = async () => {
    setEmail(null);
    setLoginMethods([]);
    setLoginStep("email");
  };

  return (
    <>
      <div
        className="absolute left-0 right-0 top-0 z-10 flex-col gap-4"
        style={{ transform: `translateY(${alertsTranslateY}px)` }}
      >
        {!!infoMsg && (
          <Alert
            color="success"
            icon={HiCheckCircle}
            className="w-full -translate-y-1/2"
            onDismiss={handleDismissInfoAlert}
          >
            <span className="text-sm font-semibold">{infoMsg}</span>
          </Alert>
        )}

        {showResetSuccess && (
          <Alert
            color="success"
            icon={HiCheckCircle}
            className="w-full -translate-y-1/2"
            onDismiss={handleDismissResetSucess}
          >
            <span className="text-sm font-semibold">
              Your password has been changed successfully!
            </span>
          </Alert>
        )}

        {showClaimSuccess && (
          <Alert
            color="success"
            icon={HiCheckCircle}
            className="w-full -translate-y-1/2"
            onDismiss={handleDismissClaimSuccess}
          >
            <span className="text-sm font-semibold">
              Your email has been verified successfully!
            </span>
          </Alert>
        )}
      </div>

      {
        {
          email: (
            <LoginEmailForm
              email={email}
              setEmail={setEmail}
              signin={lookupLoginEmail}
              initialEmailLookup={initialEmailLookup}
            />
          ),
          method: (
            <LoginMethodForm
              email={email}
              methods={loginMethods}
              select={selectLoginMethod}
              resetStep={resetLoginStep}
            />
          ),
          password: (
            <LoginPasswordForm email={email} resetStep={resetLoginStep} />
          ),
        }[loginStep]
      }

      {/* <PopupModal
        dismissible={true}
        show={showClaimSuccess}
        size={"sm"}
        onClose={() => setShowClaimSuccess(false)}
      >
        <Modal.Header>Your email is verified.</Modal.Header>
        <Modal.Body>
          Your email has been verified successfully!
          <br />
          Access your account by logging in.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="h-[45px] w-40"
            onClick={() => setShowClaimSuccess(false)}
          >
            Login <HiArrowNarrowRight className="h-5 w-5" />
          </Button>
        </Modal.Footer>
      </PopupModal>

      <PopupModal
        dismissible={true}
        show={showResetSuccess}
        size={"md"}
        onClose={() => setShowResetSuccess(false)}
      >
        <Modal.Header>Password reset successful.</Modal.Header>
        <Modal.Body>
          Your password has been changed successfully!
          <br />
          Access your account by logging in.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="h-[45px] w-40"
            onClick={() => setShowResetSuccess(false)}
          >
            Login <HiArrowNarrowRight className="h-5 w-5" />
          </Button>
        </Modal.Footer>
      </PopupModal> */}
    </>
  );
}
