import { isDemoHost, isDevHost, isLocalHost, isProdHost } from "./environments";
import {
  DASHBOARD_HOSTNAME,
  DEMO_HOSTNAME,
  DEV_DASHBOARD_HOSTNAME,
  DEV_HOSTNAME,
  PROD_HOSTNAME,
} from "./hostnames";

export const OFFICIAL_DOMAIN = "cybercert.ai";

export const LOCAL_LANDING = "http://localhost:3000";
export const LOCAL_DASHBOARD = "http://localhost:3001";
export const LOCAL_APP = "http://localhost:3002";

export const getAppDestination = (dashboard = true) => {
  if (isLocalHost()) {
    return dashboard ? LOCAL_DASHBOARD : LOCAL_APP;
  } else if (isDevHost()) {
    return dashboard
      ? `https://${DEV_DASHBOARD_HOSTNAME}`
      : `https://${DEV_HOSTNAME}`;
  } else if (isProdHost()) {
    return dashboard
      ? `https://${DASHBOARD_HOSTNAME}`
      : `https://${PROD_HOSTNAME}`;
  } else if (isDemoHost()) {
    return `https://${DEMO_HOSTNAME}`;
  } else {
    return LOCAL_LANDING;
  }
};
