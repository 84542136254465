import {
  IconBronze,
  iconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from 'assets';

export const ROUTES = {
  SELF: {
    // for Route
    ROOT: "",
    LOGIN: "login",
    PARTNER_LOGIN: "partner-login",
    CLAIM: "claim",
    RESET: "reset",
    TERMS: "terms",
    PRIVACY_POLICY: "privacy-policy",
    COOKIE_POLICY: "cookie-policy",
    CPS: "SMB1001-2023CPS.pdf",
    T2_INVITE: "invite/:token",
    PARTNER_OFFER: "offer/:token",
    PARTNER_INVITE: "partner-register",
    PARTNER_FORM: "partner-form",
    PARTNER_FORM_OLD: "partner-signup",
    PARTNER_FORM_REF: "ref/:code",
    MAINTENANCE: "maintenance",
    QR: "QR/:code",
  },
  PAGE_NOT_FOUND: "*",
  404: "/404",
};

Object.keys(ROUTES.SELF).forEach((k) => {
  // for navigate, Link
  ROUTES[k] = `/${ROUTES.SELF[k]}`;
});

export const DEFAULT_LANGUAGE = "en-au";
export const LANGUAGES = ["en-au", "fr-fr"];

export const LEVEL_NAMES = {
  Bronze: 0,
  Silver: 1,
  Gold: 2,
  Platinum: 3,
  Diamond: 4,
};

export const LEVEL_ICONS = [
  IconBronze,
  IconSilver,
  IconGold,
  IconPlatinum,
  iconDiamond,
];

export const STANDARD_LINK = "https://www.cscau.com.au/smb1001";
