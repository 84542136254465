import { AccordionTheme } from 'assets/themes';
import {
  LegalList,
  LegalListItem,
} from 'components';
import {
  Accordion,
  Flowbite,
  Table,
} from 'flowbite-react';

import { LegalPageHeader } from '../page-header';

const updateDate = "28 August 2023";

export function PrivacyPolicy() {
  const tableTerm = "font-medium text-gray-900";
  const sectionTitle =
    "text-[32px] tablet:text-[38px] font-semibold text-black leading-[45px] tablet:leading-tight";

  return (
    <>
      <LegalPageHeader pageTitle={"Privacy Policy"} updateDate={updateDate} />

      <section className="legal-content -mt-[250px] tablet:-mt-[180px]">
        <section>
          <div className="flex flex-col items-start gap-4 self-stretch rounded-lg border border-gray-200 bg-white p-[30px] shadow-sm">
            <div className="text-gray-600">
              <span className="font-semibold text-gray-900">
                This privacy policy applies to CyberCert Pty Ltd and our
                handling of personal information. CyberCert (ABN 87 662 681 423)
                is a technology company that provides Software as a Service
                (SaaS) and Platform as a Service solutions for governments and
                organizations (i.e. our customers).
              </span>
              <br />
              <br />
              Due to the commercial nature of our services, CyberCert, for the
              most part, collects the information of individuals in their
              professional capacity. For example, we collect information of the
              person who registers or activates the organization's administrator
              or user account, as part of their role within the customer
              organization.
              <br />
              <br />
              That being said, we recognize and value the protection of your
              personal information, which is an important part of our
              relationship with customers.
              <br />
              <br />
              This Privacy Policy explains how we handle personal information.
              As an Australian company, we apply the privacy principles set out
              in the Australian Privacy Act 1988 (Cth), which guide how we
              collect and manage personal information. Importantly, we:
            </div>

            <div className="-mt-[20px] ml-2">
              <LegalList>
                <LegalListItem>
                  give you clear information about our personal information
                  handling practices,
                </LegalListItem>
                <LegalListItem>
                  only collect personal information that is necessary for our
                  functions,
                </LegalListItem>
                <LegalListItem>
                  understand the purpose of our services, and restrict our use
                  and disclosure of personal information in that regard, and
                </LegalListItem>
                <LegalListItem>
                  take reasonable steps to keep the personal information we have
                  secure.
                </LegalListItem>
              </LegalList>
            </div>
          </div>
        </section>
      </section>

      <section className="legal-content py-[80px] tablet:pb-[128px]">
        <ul className="flex flex-col gap-[80px] text-gray-700">
          <li className="flex flex-col gap-[30px]">
            <span className="mt-0 text-[32px] font-semibold text-black tablet:text-[38px]">
              Personal Information
            </span>
            <div>
              Personal information is information that identifies a person (or
              could reasonably lead to them being identified).
              <br />
              <br />
              When providing our services, we collect some personal information.
              The types of personal information we collect and process depends
              on our relationship with you. We generally collect and process
              personal information from the following groups of people:
            </div>

            <div className="-mt-[30px] ml-2">
              <LegalList>
                <LegalListItem>
                  <span className="font-semibold text-blue-600">
                    Website visitors
                  </span>{" "}
                  – We collect some personal information when people visit this
                  website.
                </LegalListItem>
                <LegalListItem>
                  <span className="font-semibold text-blue-600">
                    Customers and prospective customers
                  </span>{" "}
                  – Our customers are generally organizations, companies,
                  corporations and government departments or agencies. The
                  personal information we collect includes, for example, details
                  of the contact person registered on the organization's
                  administrator or user account.
                </LegalListItem>
                <LegalListItem>
                  <span className="font-semibold text-blue-600">
                    Customers that use our SaaS and PaaS services
                  </span>{" "}
                  – When our customers activate their administrator or user
                  account, we require some personal information to register the
                  account. This information is limited and relates to details of
                  the person in their professional capacity, i.e. business email
                  and phone.
                </LegalListItem>
                <LegalListItem>
                  <span className="font-semibold text-blue-600">
                    People who are interested in working for CyberCert
                  </span>{" "}
                  – We collect personal information of job applicants, and
                  (where relevant) an applicant's referees.
                </LegalListItem>
              </LegalList>
            </div>
          </li>
          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>
              Personal Information We Collect{" "}
            </span>

            <div>
              The types of personal information we collect and process are set
              out below. We generally collect this information when you interact
              with our platform and website. We may also collect this
              information in person, via email, mail or phone.
            </div>

            <div>
              <Flowbite
                theme={{
                  theme: {
                    accordion: { AccordionTheme },
                  },
                }}
              >
                <Accordion theme={AccordionTheme}>
                  <Accordion.Panel>
                    <Accordion.Title>Analytics Data</Accordion.Title>
                    <Accordion.Content>
                      We collect and use analytics and cookie data from a
                      person's use of the website{" "}
                      <a
                        href="https://cybercert.ai"
                        className="text-blue-600 outline-none hover:underline focus:underline"
                      >
                        www.cybercert.ai
                      </a>
                      , to understand how people interact with our site.
                      <br />
                      <br />
                      We generally collect this information through the use of
                      cookies. More information about this is contained in our
                      Cookie Notice.
                      <br />
                      <br />
                      Information we collect and process when you use our
                      website and platforms includes:
                      <br />
                      <br />
                      <div className="-mt-[20px] ml-2">
                        <LegalList>
                          <LegalListItem>
                            Your device's IP address
                          </LegalListItem>
                          <LegalListItem>
                            The date and time that you visited our website
                          </LegalListItem>
                          <LegalListItem>
                            Whether you have been to our website before{" "}
                          </LegalListItem>
                          <LegalListItem>
                            What site referred you to our website
                          </LegalListItem>
                          <LegalListItem>
                            Approximate location of the device you used to
                            interact with our website
                          </LegalListItem>
                        </LegalList>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title>Inquiries Information</Accordion.Title>
                    <Accordion.Content>
                      If you are a contact person for an organization, we
                      collect and process minimal personal information when you
                      ask about our platforms and services. Inquiries can be
                      made via email, post, or when you submit an online
                      'contact us' form.
                      <br />
                      <br />
                      Information we collect and process may include the name of
                      an organization's contact person, the organization's
                      preferred phone, email and address details, and details of
                      the inquiry or correspondence with us.
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title>
                      Customer Contact Information
                    </Accordion.Title>
                    <Accordion.Content>
                      We may use the details of a customer's nominated contact
                      person to communicate with our customer, including in
                      regards to the platform, our services, sales, and
                      accounts. Information we collect and process may include
                      the name of the customer's contact person, the
                      organization's preferred phone, email and address details.
                      <br />
                      <br />
                      We may send promotional emails to a customer's nominated
                      contact person, where they have opted to receive marketing
                      material. Whilst these emails may be sent to a customer's
                      contact person, they are intended for our customers, i.e.
                      organizations. If at any time the customer wishes not to
                      receive promotional communication from us, they may
                      unsubscribe using the unsubscribe method described in the
                      email.
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title>
                      Customer Registration Information
                    </Accordion.Title>
                    <Accordion.Content>
                      We collect and process limited personal information to
                      register or activate our customer's CyberCert account. We
                      collect details of the customer's nominated person via our
                      platform when you register your organization's account,
                      including:
                      <br />
                      <br />
                      <div className="-mt-[20px] ml-2">
                        <LegalList>
                          <LegalListItem>First name</LegalListItem>
                          <LegalListItem>Last name</LegalListItem>
                          <LegalListItem>Country location</LegalListItem>
                          <LegalListItem>Business mobile number</LegalListItem>
                          <LegalListItem>Business email</LegalListItem>
                          <LegalListItem>Password</LegalListItem>
                          <LegalListItem>
                            Role within the customer organization
                          </LegalListItem>
                          <LegalListItem>
                            Profile picture (where it includes an image of a
                            person)
                          </LegalListItem>
                        </LegalList>
                      </div>
                      <br />
                      When a customer uses our platform, they may provide us
                      with contact information or other personal information
                      that relates to an individual in their professional
                      capacity, working for our customer's suppliers or
                      customers. We collect and use this information for the
                      purpose of providing our cyber security services to our
                      customers.
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title>
                      Help and Support Information
                    </Accordion.Title>
                    <Accordion.Content>
                      A customer may submit a question or request support by
                      submitting a form located within the customer's
                      administrator or user account. When the customer requests
                      support, we collect the name of the customer's contact
                      person, business email address and details of the
                      question, issue or support request, and our correspondence
                      with you in regard to the request.
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title>Payment information</Accordion.Title>
                    <Accordion.Content>
                      We collect and process payment information to provide our
                      products and services. When customers provide payment
                      information to us, they will usually provide the payment
                      information of the customer organization; not payment
                      information of an individual.
                      <br />
                      <br />
                      There may be limited circumstances where personal payment
                      information is provided to and processed by us (such as
                      name on card, card type, amount purchased). We do not
                      knowingly receive payment information of a person; rather,
                      we ask our customers to use their approved corporate
                      payment facilities.
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title>Feedback Information</Accordion.Title>
                    <Accordion.Content>
                      We collect and process feedback information to improve the
                      types and quality of services offered, and the manner in
                      which those services are provided to customers. We may
                      collect your opinions and feedback by conducting surveys
                      or market research, or by seeking other information from
                      you on a periodic basis.
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title>
                      Access and Correction Request or Privacy Complaint
                      Information
                    </Accordion.Title>
                    <Accordion.Content>
                      When you submit a request to access or correct your
                      personal information, or submit a privacy complaint, we
                      use this information to process your request or
                      investigate your concern, and to communicate with you. We
                      collect and use your name, contact information and details
                      regarding your request or concern.
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title>Recruitment Information</Accordion.Title>
                    <Accordion.Content>
                      When you apply for a job with us, we collect and process
                      your personal information as part of the application, and
                      potentially, the hiring process. Recruitment information
                      we collect and process may include name, email, phone,
                      address, resume, cover letter details and references.
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
              </Flowbite>
            </div>
          </li>
          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>
              Why we collect and use Personal Information
            </span>

            <div>
              We collect and process personal information for a number of
              reasons, which have been described in the table below:
            </div>

            <Table>
              <Table.Head>
                <Table.HeadCell className="bg-gray-200">
                  We collect and use...
                </Table.HeadCell>
                <Table.HeadCell className="bg-gray-200">Purpose</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row>
                  <Table.Cell className={tableTerm}>Analytics Data</Table.Cell>
                  <Table.Cell>
                    To analyze the usage of our website and platform, including
                    through the use of cookies, to improve your user experience.
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className={tableTerm}>
                    Inquiries Information
                  </Table.Cell>
                  <Table.Cell>
                    To answer your questions about our cyber security maturity
                    assessment platform and services
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className={tableTerm}>
                    Customer Contact Information{" "}
                  </Table.Cell>
                  <Table.Cell>
                    To enter into a contract with you (e.g. Terms of Use)
                    <br />
                    To provide you with access to the CyberCert platform
                    <br />
                    To communicate with you as part of our cyber security
                    services
                    <br />
                    To send our customers promotional emails. Whilst these
                    emails may be sent to a customer's contact person, they are
                    intended for our customers organizations.
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className={tableTerm}>
                    Help And Support Information
                  </Table.Cell>
                  <Table.Cell>
                    To provide help and support in our customer's use of the
                    CyberCert platform
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className={tableTerm}>
                    Payment Information
                  </Table.Cell>
                  <Table.Cell>
                    To accept payment for your purchase of our products or
                    services
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className={tableTerm}>
                    Feedback Information
                  </Table.Cell>
                  <Table.Cell>
                    To improve the types and quality of services offered, and
                    the manner in which those services are provided to customers
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className={tableTerm}>
                    Access And Correction Requests, and Privacy Complaint
                    Information
                  </Table.Cell>
                  <Table.Cell>
                    To process your request or investigate your concern, and
                    communicate with you
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell className={tableTerm}>
                    Recruitment Information{" "}
                  </Table.Cell>
                  <Table.Cell>
                    To process your job application and, if you are successful,
                    to offer you a job and commence your employment
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </li>
          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>
              Who we share your Personal Information with
            </span>

            <div>
              CyberCert uses external parties (i.e. vendors) to provide services
              and functions on our behalf. In order for vendors to provide these
              services, we may share personal information that relates to the
              services being provided. We ensure that vendors only process
              personal information for the purpose it was provided to them, and
              not for any other purpose.
            </div>

            <div className="font-semibold">
              Our primary vendors that provide services on our behalf include:
            </div>

            <div className="-mt-[30px] ml-2">
              <LegalList>
                <LegalListItem>
                  <a
                    href="https://marketingplatform.google.com/about/analytics/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-blue-600 outline-none hover:underline focus:underline"
                  >
                    Google Analytics
                  </a>{" "}
                  – provide analytics services, refer to the{" "}
                  <a
                    href="https://policies.google.com/privacy?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 outline-none hover:underline focus:underline"
                  >
                    Google Privacy Policy
                  </a>
                  .
                </LegalListItem>

                <LegalListItem>
                  <a
                    href="https://workspace.google.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-blue-600 outline-none hover:underline focus:underline"
                  >
                    Google
                  </a>{" "}
                  - provides data storage and SaaS related services, refer to
                  the{" "}
                  <a
                    href="https://policies.google.com/privacy?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 outline-none hover:underline focus:underline"
                  >
                    Google Privacy Policy
                  </a>
                  .
                </LegalListItem>

                <LegalListItem>
                  <a
                    href="https://aws.amazon.com/ru/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-blue-600 outline-none hover:underline focus:underline"
                  >
                    Amazon Web Services (AWS)
                  </a>{" "}
                  – provides public cloud and IaaS related services, refer to
                  the{" "}
                  <a
                    href="https://aws.amazon.com/privacy/?nc1=f_pr"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 outline-none hover:underline focus:underline"
                  >
                    AWS Privacy Notice
                  </a>
                  .
                </LegalListItem>

                <LegalListItem>
                  <a
                    href="https://www.hubspot.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-blue-600 outline-none hover:underline focus:underline"
                  >
                    HubSpot
                  </a>{" "}
                  – provides our cloud based CRM services, refer to the{" "}
                  <a
                    href="https://legal.hubspot.com/privacy-policy?_ga=2.79006228.1086938271.1620356416-426976951.1620356416"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 outline-none hover:underline focus:underline"
                  >
                    HubSpot Privacy Policy
                  </a>
                  .
                </LegalListItem>

                <LegalListItem>
                  <a
                    href="https://www.zendesk.com/au/#georedirect"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-blue-600 outline-none hover:underline focus:underline"
                  >
                    ZenDesk
                  </a>{" "}
                  – provides our customers efficient help desk and customer
                  service, refer to the{" "}
                  <a
                    href="https://www.zendesk.com/au/company/agreements-and-terms/privacy-notice/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 outline-none hover:underline focus:underline"
                  >
                    Zendesk Privacy Policy
                  </a>
                  .
                </LegalListItem>

                <LegalListItem>
                  <a
                    href="https://stripe.com/au"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold text-blue-600 outline-none hover:underline focus:underline"
                  >
                    Stripe
                  </a>{" "}
                  – provides our customers a secure payment portal, refer to the{" "}
                  <a
                    href="https://stripe.com/au/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 outline-none hover:underline focus:underline"
                  >
                    Stripe Privacy Policy
                  </a>
                  .
                </LegalListItem>
              </LegalList>
            </div>

            <div>
              We also have contracts with other domain specialists, subject
              matter experts, management consultants and information technology
              professionals to assist in providing our services. There may be
              limited instances where we share your information with these
              external parties for the purpose of providing services on our
              behalf.
            </div>

            <div>
              We do not sell or share personal information with any advertisers,
              sponsors, content providers, media outlets, law enforcement or
              other person or entity, unless:
            </div>

            <div className="-mt-[30px] ml-2">
              <LegalList>
                <LegalListItem>
                  We have your express permission, or
                </LegalListItem>
                <LegalListItem>
                  There is a lawful ability or requirement for us to do so.
                </LegalListItem>
              </LegalList>
            </div>
          </li>
          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>Anonymity</span>

            <div>
              If you contact us with a general question, we may interact with
              you anonymously or through the use of pseudonym.
            </div>

            <div>
              However, due to the nature of our business, we are unable to
              provide our cyber security maturity assessment platform
              anonymously, as we require factual information in order to
              register customer accounts and provide our cyber security maturity
              assessment services.
            </div>
          </li>
          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>
              How we manage Personal Information
            </span>

            <div>
              At CyberCert, we securely manage and dispose of personal
              information that we collect and process, as outlined below:
            </div>

            <Accordion>
              <Accordion.Panel>
                <Accordion.Title>Storage</Accordion.Title>
                <Accordion.Content>
                  <div>
                    We store personal information that we collect using public
                    cloud services that are secured using best practice
                    authentication techniques to protect your data from
                    unauthorized access, modification or disclosure. CyberCert
                    stores data, including personal information, with Google and
                    Amazon Web Services. The information is stored in the
                    jurisdiction in which we collected it from (for example,
                    where personal information is collected from within the US,
                    the data is stored on servers located int the US) when it is
                    possible to do so.
                    <br />
                    <br />
                    We also use vendors to store specific types of personal
                    information (such as analytics and accounting information)
                    on our behalf. The personal information stored by vendors
                    relates directly to their functions and services, and is
                    stored in accordance with our contract with these providers.
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title>Security</Accordion.Title>
                <Accordion.Content>
                  <div>
                    CyberCert have implemented a range of data security
                    practices and controls, including (but not limited to):
                  </div>
                  <br />
                  <div className="-mt-[20px] ml-2">
                    <LegalList>
                      <LegalListItem>
                        Use of access controls, including multi-factor
                        authentication,
                      </LegalListItem>
                      <LegalListItem>
                        Security awareness training,{" "}
                      </LegalListItem>
                      <LegalListItem>
                        Encryption of personal information during transit and at
                        rest where possible,
                      </LegalListItem>
                      <LegalListItem>
                        Personal information is stored and backed up in secure
                        offsite locations,{" "}
                      </LegalListItem>
                      <LegalListItem>
                        Back-ups of data is regularly tested for full
                        operational recovery,{" "}
                      </LegalListItem>
                      <LegalListItem>
                        Use of anti-virus on all company workstations and
                        laptops, and{" "}
                      </LegalListItem>
                      <LegalListItem>
                        Installation of firewalls where our network connects to
                        the public internet.
                      </LegalListItem>
                    </LegalList>
                  </div>
                  <br />
                  <div>
                    Protection of personal information from unauthorized access,
                    disclosure, alteration or loss is a priority for us. Any
                    concerns about the security of personal information
                    collected or processed by CyberCert should be reported to us
                    at using our contact details below. public internet.
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title>Retention</Accordion.Title>
                <Accordion.Content>
                  We keep your personal information for different periods,
                  depending on the purpose that it was collected for. Where we
                  no longer require personal information for the purpose it was
                  collected, we will securely destroy that data.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </li>
          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>
              Accessing and correcting your Personal Information
            </span>

            <div>
              CyberCert supports your right to: Access personal information we
              hold about you, or Correct your personal information, where you
              think that it is inaccurate, incomplete or out of date.
            </div>

            <div>
              <ol className="flex list-decimal flex-col gap-2 pl-8">
                <li>
                  <div className="ml-3 font-semibold">
                    Access personal information we hold about you, or
                  </div>
                </li>
                <li>
                  <div className="ml-3 font-semibold">
                    Correct your personal information, where you think that it
                    is inaccurate, incomplete or out of date.
                  </div>
                </li>
              </ol>
            </div>

            <div>
              If you would like to access personal information we hold about
              you, we are happy to tell you what it is. We will not, however,
              tell someone else what personal information we hold about you
              (unless you permit us or there is a lawful ability or requirement
              for us to do so).
            </div>

            <div>
              If you think the personal information we hold about you is
              incorrect, out of date or misleading, we are happy to correct it.
            </div>
          </li>

          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>Your Legal Rights</span>

            <div>Your rights include the following:</div>

            <ol className="flex list-decimal flex-col gap-2 pl-8 font-medium text-gray-900">
              <li>
                <div className="ml-3">
                  You may request us to stop sending you marketing.
                </div>
              </li>
              <li>
                <div className="ml-3">
                  You may request us to stop using your personal information
                  where we are doing so under legitimate interests unless it is
                  needed for dealing with legal claims or we have other
                  compelling legitimate reasons that override your rights.
                </div>
              </li>
              <li>
                <div className="ml-3">
                  You may access the personal information we hold on you.
                </div>
              </li>
              <li>
                <div className="ml-3">
                  There are some limited exceptions to this right, such as
                  information relating to others who have not consented to the
                  disclosure of their information and information which is
                  legally privileged.
                </div>
              </li>
              <li>
                <div className="ml-3">
                  You may ask us to correct the personal information that you
                  have shared with us if that information is inaccurate. We are
                  not able to amend any data that has been created as a result
                  of a transaction with us.
                </div>
              </li>
              <li>
                <div className="ml-3">
                  You may ask for personal information which identifies you to
                  be removed (anonymized). To do this the information that
                  identifies you will be removed from our active systems.
                  However, a separate and restricted copy of the identifying
                  information may be kept for 7 years to meet the obligations we
                  have to law enforcement, national authorities and legal
                  proceedings. This right only applies under data protection
                  laws in the European Union, the UK and some other countries.
                  We will handle all requests in accordance with applicable law.
                  However, depending on the right you wish to exercise, and the
                  nature of the personal information involved, there may be
                  legal reasons why we cannot grant your request. Subject to
                  law, your personal data will be deleted upon request by you.
                  To exercise any of these rights, including to delete your
                  personal data, please contact us via the “Help” button located
                  on this website.
                </div>
              </li>
            </ol>

            <div>
              We will handle all requests in accordance with applicable law.
              However, depending on the right you wish to exercise, and the
              nature of the personal information involved, there may be legal
              reasons why we cannot grant your request.
            </div>

            <div>
              Subject to law, your personal data will be deleted upon request by
              you.
            </div>

            <div>
              To exercise any of these rights, including to delete your personal
              data, please contact us via the “Help” button located on this
              website.
            </div>
          </li>

          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>Questions and Concerns?</span>

            <div>
              If you have a question about this Privacy Policy or are concerned
              about how we handle personal information, please contact us at:
            </div>

            <Accordion>
              <Accordion.Panel>
                <Accordion.Title>Online</Accordion.Title>
                <Accordion.Content>
                  <div>
                    Via the Help & Support contact form located in the bottom
                    corner of this page
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title>Address</Accordion.Title>
                <Accordion.Content>
                  <div>
                    <span className="font-semibold text-black">
                      CyberCert Pty Ltd
                    </span>
                    <br />
                    60 Martin Place
                    <br />
                    Sydney, NSW 2000
                    <br />
                    Australia
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <div>
              If you have made a privacy compliant and are not happy with how we
              responded to your concern, you are able to contact the Office of
              the Australian Information Commissioner (OAIC). The OAIC’s process
              is available here.
            </div>
          </li>

          <li className="flex flex-col gap-[30px]">
            <span className={sectionTitle}>Updates to this Privacy Policy</span>

            <div>
              We may decide to update this Privacy Policy to ensure that our
              personal information handling practices are correctly reflected.
              If we make a significant change to this policy, we will notify you
              by publishing a notice on our website.
              <br />
              <br />
              <span className="font-medium text-black">
                This policy was last revised on {updateDate}.
              </span>
            </div>
          </li>
        </ul>
      </section>
    </>
  );
}
