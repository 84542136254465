import { useRef } from "react";

import { TextInputTheme } from "assets";
import { TextInput as FlowbiteTextInput } from "flowbite-react";
import { ensureHttps } from "services";

export function TextInput({ className, ...props }) {
  let ref = useRef();

  const handleOnBlur = (event) => {
    if (ref?.current?.type === "url") {
      if (ref && ref.current) {
        ref.current.value = ensureHttps(ref.current.value);
      }
    }
  };

  return (
    <FlowbiteTextInput
      className={className}
      ref={ref}
      theme={TextInputTheme}
      onBlur={handleOnBlur}
      {...props}
    />
  );
}
