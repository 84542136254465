import { LoadingScreen } from "components";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "variables";

export function PartnerSignupPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(`${ROUTES.PARTNER_FORM}${location.search}`);
  }, []);

  return (
    <>
      <LoadingScreen />
    </>
  );
}
