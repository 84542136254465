import {
  BETA_HOSTNAME,
  DEMO_HOSTNAME,
  DEMO_LANDING_HOSTNAME,
  DEV_HOSTNAME,
  DEV_LANDING_HOSTNAME,
  LANDING_HOSTNAME,
  PROD_HOSTNAME,
  WWW_LANDING_HOSTNAME,
} from "./hostnames";

export const isLocalHost = () =>
  Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export const isDevHost = () =>
  Boolean(
    window.location.hostname === DEV_HOSTNAME ||
      window.location.hostname === DEV_LANDING_HOSTNAME
  );

export const isBetaHost = () =>
  Boolean(window.location.hostname === BETA_HOSTNAME);

export const isDemoHost = () =>
  Boolean(
    window.location.hostname === DEMO_HOSTNAME ||
      window.location.hostname === DEMO_LANDING_HOSTNAME
  );

export const isProdHost = () =>
  Boolean(
    window.location.hostname === PROD_HOSTNAME ||
      window.location.hostname === LANDING_HOSTNAME ||
      window.location.hostname === WWW_LANDING_HOSTNAME
  );
