import { useEffect, useState } from "react";

export const delay = (milliseconds) =>
  new Promise((resolve) => setTimeout(() => resolve(), milliseconds));

export const clearUrlParamsState = () => {
  window.history.replaceState(
    null,
    "",
    `${window.location.origin}${window.location.pathname}`,
  );
};

export const sanitizeInputWithSpace = (s) => {
  return s?.replace(/\s/g, "") || "";
};

export const openZEWidget = () => {
  window.zE.activate();
};

export const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { windowWidth, windowHeight };
};

export const handleKeyDownInput = (event) => {
  if (
    (event?.key === " " || event?.key === "Enter") &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    event?.preventDefault();
    event?.target?.click();
  }
};

export const handleKeyDownSubmission = (event, target) => {
  if (
    event?.key === "Enter" &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    target?.current?.click();
  }
};

export const handleEnterKeyFormSubmission = (event, callback = () => {}) => {
  if (
    event?.key === "Enter" &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    callback();
  }
};

// Sleep function using Promise and setTimeout
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
