import { ReactComponent as PageNotFoundImg } from "./images/404-img.svg";
import certCirleWeb from "./images/cert-circle.webp";
import cscauBadgeWeb from "./images/cscau-badge.webp";
import CoreCyberLogoJpeg from "./images/dummy-logos/cor-cyber.jpeg";
import heroWomanWeb from "./images/hero-woman.webp";
import iconBronzePng from "./images/icon-level1-bronze.png";
import iconSilverPng from "./images/icon-level2-silver.png";
import iconGoldPng from "./images/icon-level3-gold.png";
import iconPlatinumPng from "./images/icon-level4-platinum.png";
import iconDiamondPng from "./images/icon-level5-diamond.png";
// import laptopManWeb from "./images/home-manwithlaptop.webp";
import { ReactComponent as CyberCertLogoSecondary } from "./images/logo-secondary.svg";
import { ReactComponent as CyberCertLogo } from "./images/logo.svg";
import MicrosoftLogoPng from "./images/microsoft-logo.png";
import MSILogoPng from "./images/msi-logo.png";
import noMobileWeb from "./images/no-mobile.webp";
// import laptopManWeb from "./images/home-manwithlaptop.webp";

export { CyberCertLogo, CyberCertLogoSecondary, PageNotFoundImg };
// export const LaptopMan = laptopManWeb;
export const CSCAUBadge = cscauBadgeWeb;
export const noMobile = noMobileWeb;
export const CertCircle = certCirleWeb;
export const HeroWoman = heroWomanWeb;
export const IconBronze = iconBronzePng;
export const IconSilver = iconSilverPng;
export const IconGold = iconGoldPng;
export const IconPlatinum = iconPlatinumPng;
export const iconDiamond = iconDiamondPng;
export const MSILogo = MSILogoPng;
export const MicrosoftLogo = MicrosoftLogoPng;
export const CoreCyberLogo = CoreCyberLogoJpeg;

export * from "./themes";
