import { useRef, useState } from "react";

import { getOrganisationPublic } from "api";
import ABNResult from "components/abn-result/abn-result";
import { ButtonWithRef } from "components/buttons";
import { TextInput } from "components/input";
import { PopupModal } from "components/modal";
import { Modal } from "flowbite-react";
import { HiOfficeBuilding } from "react-icons/hi";
import {
  ABNService,
  filterNumberAndSpaceKeyPress,
  filterNumberAndSpacePaste,
  handleKeyDownSubmission,
  openZEWidget,
  sanitizeInputWithSpace,
} from "services";

export function CheckoutModal({
  active,
  setActive,
  ABN,
  setABN,
  continueFn = async () => {},
  checkoutLevel,
}) {
  const [abnSearchRes, setAbnSearchRes] = useState();
  const [abnSearchErr, setAbnSearchErr] = useState();
  const [abnAvailable, setAbnAvailable] = useState(false);
  const [abnRegistered, setAbnRegistered] = useState(false);
  const [orgSearchRes, setOrgSearchRes] = useState();
  const submitRef = useRef(null);

  const submitSearch = async (event) => {
    event.preventDefault();

    await searchABN(ABN);
  };

  const searchABN = async () => {
    setABN(sanitizeInputWithSpace(ABN));
    const lookupResult = await ABNService.lookup({ abn: ABN });

    const existedOrganisation = lookupResult?.organisation;
    let result = lookupResult.result;
    let customerLevel = existedOrganisation?.customer?.level || 0;

    setOrgSearchRes({
      ...existedOrganisation,
      certifiedStatus:
        // if customer is not certified or less than partner's offered level, -1,
        // if equal, 0, if higher, 1
        !existedOrganisation || customerLevel < checkoutLevel
          ? -1
          : customerLevel === checkoutLevel
            ? 0
            : 1,
    });

    if (!!lookupResult.error) {
      setAbnSearchErr(lookupResult.error);
    } else if (!existedOrganisation) {
      setAbnAvailable(true);
      setAbnRegistered(false);
    } else if (existedOrganisation.confirmed) {
      setAbnSearchErr(result);
      setAbnRegistered(true);
    } else {
      setAbnAvailable(true);
      setAbnRegistered(false);
    }

    setAbnSearchRes(result);

    return result;
  };

  const resetSearch = () => {
    setAbnSearchRes(undefined);
    setAbnRegistered(false);
    setAbnSearchErr(false);
    setABN("");
  };

  const proceed = async () => {
    await continueFn();

    setAbnSearchRes(undefined);
    setAbnRegistered(false);
    setAbnSearchErr(false);
  };

  return (
    <PopupModal
      dismissible={true}
      show={active}
      size={"md-static"}
      onClose={() => {
        resetSearch();
        setActive(false);
      }}
    >
      {/* <Modal.Header>Get Started</Modal.Header> */}
      <Modal.Body>
        <div className="input-form flex flex-col gap-[16px]">
          <div className="flex flex-col items-start gap-[51px] self-stretch">
            <div className="title w-full align-middle text-[38px] font-semibold text-black laptop:h-[53px]">
              Get started
            </div>
            <div className="flex flex-col gap-4 self-stretch">
              {abnRegistered ? (
                <>
                  <ABNResult
                    abnSearchRes={abnSearchRes}
                    isError={true}
                    resetSearch={resetSearch}
                  />
                </>
              ) : (
                <>
                  {!!abnSearchRes ? (
                    <>
                      <ABNResult
                        abnSearchRes={abnSearchRes}
                        isError={false}
                        resetSearch={resetSearch}
                      />
                    </>
                  ) : (
                    <>
                      <div className="subtitle text-[14px] leading-normal text-gray-600">
                        Enter the ABN of the organisation that you <br />
                        are wanting to get certified.
                      </div>

                      {!!abnSearchErr && (
                        <>
                          <div className="font-medium text-red-600 text-sm">
                            ABN is invalid. Please try again.
                          </div>
                        </>
                      )}

                      <TextInput
                        id="ABN"
                        name="abn"
                        type="text"
                        icon={HiOfficeBuilding}
                        placeholder="Enter your ABN"
                        required
                        shadow
                        onChange={(event) => setABN(event.target.value)}
                        onKeyPress={filterNumberAndSpaceKeyPress}
                        onKeyDown={(event) =>
                          handleKeyDownSubmission(event, submitRef)
                        }
                        onPaste={filterNumberAndSpacePaste}
                        color={!!abnSearchErr ? "failure" : "gray"}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {abnRegistered ? (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col text-[14px] text-gray-900">
                <div>Cannot find your organisation or it's been claimed?</div>
                <div
                  onClick={openZEWidget}
                  className="outline-none hover:underline focus:underline"
                  tabIndex={0}
                >
                  <span className="cursor-pointer font-medium text-blue-600">
                    Get Help
                  </span>
                </div>
              </div>

              <div>
                {orgSearchRes?.certifiedStatus === 0 && (
                  <>
                    This account has already claimed this cert for{" "}
                    {new Date().getFullYear()}. You can login to your account to
                    view other levels available for purchase.
                  </>
                )}

                {orgSearchRes?.certifiedStatus === 1 && (
                  <>
                    this account has already claimed a higher certification
                    level for {new Date().getFullYear()}. Login to your account
                    to view the certification
                  </>
                )}

                {orgSearchRes?.certifiedStatus === -1 && (
                  <>
                    This account has already been claimed. Please login to your
                    account to purchase.
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              {!!abnSearchRes ? (
                <>
                  <ButtonWithRef
                    type="submit"
                    onClick={proceed}
                    disabled={!abnAvailable}
                    className="h-[44px]"
                  >
                    Continue to checkout
                  </ButtonWithRef>

                  <div className="flex flex-col text-[14px] text-gray-900">
                    <div>
                      Cannot find your organisation or it's been claimed?
                    </div>
                    <div
                      onClick={openZEWidget}
                      className="outline-none hover:underline focus:underline"
                      tabIndex={0}
                    >
                      <span className="cursor-pointer font-medium text-blue-600">
                        Get Help
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ButtonWithRef
                    className="h-[44px]"
                    ref={submitRef}
                    type="submit"
                    onClick={submitSearch}
                  >
                    <div className="flex items-center justify-center gap-[8px]">
                      Search ABN
                    </div>
                  </ButtonWithRef>

                  <div className="text-[14px] text-gray-900">
                    Can’t find your ABN?{" "}
                    <span
                      onClick={openZEWidget}
                      className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                      tabIndex={0}
                    >
                      Get Help
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </PopupModal>
  );
}
