import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";
import { useLoadingState } from "middleware";

export function LoadingScreen() {
  const { loading } = useLoadingState();

  return <>{loading.loading === true && <LoadingScreenSpinner />}</>;
}

export function LoadingScreenSpinner() {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white">
      <Spinner size="xl" theme={SpinnerTheme} color="darkGray" />
    </div>
  );
}
