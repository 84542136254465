import { API } from "aws-amplify";

export const lookupABN = async ({ ABN }) => {
  const myInit = {
    queryStringParameters: {
      ABN,
    },
  };

  return API.get("ABN", "/lookup", myInit);
};

export const verifyLookupABN = async ({ ABN, checkoutId }) => {
  const myInit = {
    queryStringParameters: {
      ABN,
      checkoutId,
    },
  };

  return API.get("ABN", "/verifyLookup", myInit);
};
