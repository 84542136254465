import { useState } from "react";

import { HiEye, HiEyeOff } from "react-icons/hi";

import { TextInput } from "./text";

export function PasswordInput({
  autoComplete,
  color,
  icon,
  id,
  name,
  placeholder,
  required,
  shadow,
  onChange = () => {},
  onKeyDown = () => {},
}) {
  const [type, setType] = useState("password");
  return (
    <div className="relative">
      <TextInput
        autoComplete={autoComplete}
        color={color}
        name={name}
        icon={icon}
        id={id}
        placeholder={placeholder}
        required={required}
        shadow={shadow}
        type={type || "password"}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />

      {type === "password" ? (
        <HiEye
          className="absolute bottom-0 left-0 right-0 top-0 m-auto mr-[12px] cursor-pointer text-gray-500"
          onClick={() => setType("text")}
        />
      ) : (
        <HiEyeOff
          className="absolute bottom-0 left-0 right-0 top-0 m-auto mr-[12px] cursor-pointer text-gray-500"
          onClick={() => setType("password")}
        />
      )}
    </div>
  );
}
