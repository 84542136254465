import { useRef, useState } from "react";

import { ButtonWithRef, TextInput } from "components";
import { HiMail } from "react-icons/hi";
import { handleKeyDownSubmission } from "services";

export function ResetFormEmail({ resetState, handleInput, proceedFn }) {
  const [err, setErr] = useState();
  const submitRef = useRef(null);

  const proceed = async () => {
    setErr(await proceedFn());
  };

  return (
    <>
      <div className="flex flex-col items-start">
        <div className="flex flex-col gap-4 self-stretch">
          <div className="subtitle text-[14px] leading-normal text-gray-600">
            Enter email address used in your account
          </div>

          {!!err?.message && (
            <div className="text-[14px] font-medium text-red-600">
              {err.message}
            </div>
          )}

          <TextInput
            id="email"
            name="email"
            type="email"
            icon={HiMail}
            placeholder="Work email address"
            required
            shadow
            value={resetState?.email || ""}
            onChange={handleInput}
            onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
            color={!!err?.message ? "failure" : "gray"}
          />
        </div>
      </div>

      <div className="flex justify-end">
        {/* <Link
          to={ROUTES.LOGIN}
          className="flex w-fit items-center gap-1 text-[14px] font-medium text-blue-600 outline-none hover:underline focus:underline"
          onKeyDown={handleKeyDownInput}
        >
          <HiReply className="h-4 w-4" />
          Back to Login
        </Link> */}

        <ButtonWithRef
          variant="small"
          ref={submitRef}
          type="submit"
          className="bg-black text-white"
          onClick={proceed}
        >
          Continue
        </ButtonWithRef>
      </div>
    </>
  );
}
