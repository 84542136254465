import { CyberCertLogoSecondary } from 'assets';
import { Link } from 'react-router-dom';
import {
  openZEWidget,
  scrollToTop,
} from 'services';
import { ROUTES } from 'variables';

export function Footer() {
  return (
    <>
      <footer
        id="cybercert-footer"
        className="w-full bg-gray-900 text-sm font-normal text-white"
      >
        <div
          className="mx-auto flex w-full flex-row justify-between px-6 py-[80px]
          tablet:px-[80px]
          laptop:max-w-[1600px] laptop:px-[145px] laptop:py-[100px]"
        >
          <div className="flex w-full flex-col gap-[50px] tablet:gap-[20px] laptop:gap-[60px]">
            <div className="flex flex-col items-start gap-[50px] tablet:flex-row tablet:justify-between laptop:gap-0">
              <div className="flex flex-col items-start gap-[30px]">
                <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-8 focus-within:outline-blue-800">
                  <CyberCertLogoSecondary
                    className="h-[27px] cursor-pointer outline-none"
                    onClick={scrollToTop}
                    tabIndex={0}
                  />
                </div>

                <div className="flex flex-col">
                  <span>CyberCert Pty Ltd</span>
                  <span>ABN 87 662 681 423</span>
                </div>
              </div>

              <div className="flex w-full flex-col justify-between gap-[50px] tablet:w-fit tablet:flex-row tablet:justify-between tablet:gap-[60px]">
                <div className="flex w-[174px] flex-col items-start gap-4">
                  <span className="text-sm text-gray-100">CONTACT US</span>
                  <span
                    className="cursor-pointer text-gray-400 hover:text-white"
                    onClick={openZEWidget}
                  >
                    Get in touch with us via our contact form.
                  </span>
                </div>

                {/* <div className="flex w-[150px] flex-col items-start gap-4">
                  <span className="text-sm text-gray-100">APAC</span>
                  <span className="text-gray-400">
                    60 Martin Place, Sydney, NSW 2000 Australia
                  </span>
                </div> */}

                <div className="flex w-[140px] flex-col items-start gap-4">
                  <span className="text-sm text-gray-100">LEGAL</span>

                  <div className="flex flex-col items-start gap-2 text-gray-400">
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.TERMS}
                        className="outline-none hover:text-white hover:underline"
                      >
                        Terms of Use
                      </Link>
                    </div>
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.PRIVACY_POLICY}
                        className="outline-none hover:text-white hover:underline"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div className="rounded-md focus-within:outline focus-within:outline-2 focus-within:outline-offset-4 focus-within:outline-blue-800">
                      <Link
                        to={ROUTES.COOKIE_POLICY}
                        className="outline-none hover:text-white hover:underline"
                      >
                        Cookie Policy
                      </Link>
                    </div>
                    {/* <Link className="hover:underline">CPS</Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-start gap-[10px] self-stretch text-gray-500 laptop:-mt-[25px] desktop:-mt-[50px]">
              <div>© Copyright 2024, All rights reserved.</div>
              <div>
                The cybercert name, logo, and badges are trademarks.
                <br />
                Patents pending: 2023903514, 2023903509, 2023903507, 2023903506.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
