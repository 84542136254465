import { HiClock } from "react-icons/hi";

export function LegalPageHeader({ pageTitle, updateDate }) {
  return (
    <>
      <section className="legal-page-bg">
        <section className="legal-content pt-[100px] tablet:pt-[150px]">
          <section>
            <div className="flex flex-col items-start gap-0.5 self-stretch px-[20px] tablet:px-0">
              <div className="title-legal">{pageTitle}</div>

              <div className="flex flex-row items-start gap-2 text-gray-800">
                <HiClock className="mt-[4px] h-5 w-5 text-gray-500" />
                <div className="text-base">
                  <span> Last updated:</span>{" "}
                  <span className="font-semibold">{updateDate}</span>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
}
