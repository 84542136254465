export const DEV_LANDING_HOSTNAME = "cmxdev.cybercert.ai";
export const DEMO_LANDING_HOSTNAME = "demo.cybercert.ai";
export const LANDING_HOSTNAME = "cybercert.ai";
export const WWW_LANDING_HOSTNAME = "www.cybercert.ai";
export const DEV_DASHBOARD_HOSTNAME = "dev.dashboard.cybercert.ai";
export const DASHBOARD_HOSTNAME = "dashboard.cybercert.ai";
export const PARTNER_DASHBOARD_HOSTNAME = "partner.dashboard.cybercert.ai";
export const DEV_HOSTNAME = "dev.certification.cybercert.ai";
export const BETA_HOSTNAME = "beta.certification.cybercert.ai";
export const DEMO_HOSTNAME = "demo.certification.cybercert.ai";
export const PROD_HOSTNAME = "certification.cybercert.ai";
