import { ButtonWithRef } from "components";
import { useNavigate } from "react-router-dom";
import { openZEWidget } from "services";
import { ROUTES } from "variables";

import { NavBarMenuItems as items } from "./navbar-menu-items";

export function NavbarMenu({ close }) {
  const navigate = useNavigate();

  return (
    <>
      <div
        id="cybercert-navbar-mobile-menu"
        className="phone:px-20 mx-auto w-full bg-white px-6 py-5 tablet:w-[710px] tablet:px-0 laptop:hidden laptop:w-[555px] laptop:px-0 desktop:w-[1283px]"
        style={{ WebkitTapHighlightColor: "transparent" }}
      >
        <ul className="my-2 flex flex-col gap-4">
          {/* <li>
            <div
              className="m-0 my-2 inline-flex cursor-pointer p-2 hover:rounded-lg hover:bg-gray-100 active:bg-gray-300"
              onClick={close}
            >
              {
                items.find((obj) => obj.id === "cybercert-menu-why-certify")
                  .content
              }
            </div>
          </li> */}
          {/* <li>
            <div
              className="m-0 my-2 inline-flex cursor-pointer p-2 hover:rounded-lg hover:bg-gray-100 active:bg-gray-300"
              onClick={close}
            >
              {items.find((obj) => obj.id === "cybercert-menu-about").content}
            </div>
          </li> */}
          <li>
            <ButtonWithRef
              variant="blank"
              className="h-[41px] w-full hover:bg-gray-100 hover:shadow focus:ring-4 focus:ring-blue-300"
              onClick={() => {
                close();
                openZEWidget();
              }}
            >
              {items.find((obj) => obj.id === "cybercert-menu-contact").content}
            </ButtonWithRef>
          </li>
          <li>
            <ButtonWithRef
              className="h-[41px] w-full"
              onClick={() => {
                close();
                navigate(ROUTES.LOGIN);
              }}
            >
              Login
            </ButtonWithRef>
          </li>
        </ul>
      </div>
    </>
  );
}
