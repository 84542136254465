import { useEffect, useState } from "react";

import { BackButton, SubmitButton, TextInput } from "components";
import { Alert } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";
import {
  handleEnterKeyFormSubmission,
  validateEmail,
  validateEmpty,
  validateMobile,
  validatePhoneNumber,
} from "services";

const COUNTRY_OPTIONS = ["Australia"];

const STATE_OPTIONS = [
  "Select State",
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
];

export function PartnerFormAdminStep({
  partnerState,
  setPartnerState,
  handleInput = () => {},
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Trim values in state
    let submitState = { ...partnerState };
    for (const key in submitState) {
      if (submitState?.hasOwnProperty(key)) {
        submitState[key] = {
          ...submitState?.[key],
          value: submitState?.[key]?.value?.trim(),
        };
      }
    }

    // Check for invalid fields
    let isInvalidField = false;
    if (!validateEmpty(submitState?.adminFirstName?.value)) {
      submitState.adminFirstName.error = true;
      isInvalidField = true;
    }
    if (!validateEmpty(submitState?.adminLastName?.value)) {
      submitState.adminLastName.error = true;
      isInvalidField = true;
    }
    if (!validateEmpty(submitState?.adminRole?.value)) {
      submitState.adminRole.error = true;
      isInvalidField = true;
    }
    if (
      !validateEmpty(submitState?.adminEmail?.value) ||
      !validateEmail(submitState?.adminEmail?.value)
    ) {
      submitState.adminEmail.error = true;
      isInvalidField = true;
    }
    if (
      !validateEmpty(submitState?.adminPhone?.value) ||
      !validatePhoneNumber(submitState?.adminPhone?.value)
    ) {
      submitState.adminPhone.error = true;
      isInvalidField = true;
    }
    if (
      !validateEmpty(submitState?.adminAuthMobile?.value) ||
      !validateMobile(submitState?.adminAuthMobile?.value)
    ) {
      submitState.adminAuthMobile.error = true;
      isInvalidField = true;
    }

    if (isInvalidField) {
      setErr({
        type: "invalid",
        msg: "You have entered an invalid field. Please try again.",
      });
      setPartnerState(submitState);
      return;
    }

    // Goto next step now
    setIsLoading(false);
    handleNextStep();
  };

  useEffect(() => {
    if (Object?.values(partnerState)?.every((obj) => !obj.error)) {
      setErr(null);
    }
  }, [partnerState]);

  return (
    <>
      {!!err?.msg && (
        <Alert
          color="red"
          icon={BiSolidErrorAlt}
          onDismiss={() => setErr(null)}
        >
          {err?.msg}
        </Alert>
      )}

      {partnerState?.adminEmail?.error &&
        !!partnerState?.adminEmail?.errorMsg && (
          <Alert
            color="red"
            icon={BiSolidErrorAlt}
            onDismiss={() => setErr(null)}
          >
            {partnerState?.adminEmail?.errorMsg}
          </Alert>
        )}

      <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Authorized Representative
            </span>

            <div className="flex gap-2">
              <TextInput
                autocomplete="off"
                className="flex-1"
                color={partnerState?.adminFirstName?.error ? "failure" : "gray"}
                disabled={isLoading}
                name="adminFirstName"
                placeholder="First Name"
                required
                type="text"
                value={partnerState?.adminFirstName?.value}
                onKeyDown={(event) =>
                  handleEnterKeyFormSubmission(event, () => handleSubmit(event))
                }
                onInput={handleInput}
              />

              <TextInput
                autocomplete="off"
                className="flex-1"
                color={partnerState?.adminLastName?.error ? "failure" : "gray"}
                disabled={isLoading}
                name="adminLastName"
                placeholder="Last Name"
                required
                type="text"
                value={partnerState?.adminLastName?.value}
                onKeyDown={(event) =>
                  handleEnterKeyFormSubmission(event, () => handleSubmit(event))
                }
                onInput={handleInput}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">Role</span>

            <TextInput
              autocomplete="off"
              color={partnerState?.adminRole?.error ? "failure" : "gray"}
              disabled={isLoading}
              name="adminRole"
              placeholder="Enter your role"
              required
              type="text"
              value={partnerState?.adminRole?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Work Email Address
            </span>

            <TextInput
              autocomplete="off"
              color={partnerState?.adminEmail?.error ? "failure" : "gray"}
              name="adminEmail"
              placeholder="Enter your work email address"
              type="email"
              required
              value={partnerState?.adminEmail?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
              disabled={isLoading || partnerState?.lockAdminEmail}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Work Phone
            </span>

            <TextInput
              autocomplete="off"
              color={partnerState?.adminPhone?.error ? "failure" : "gray"}
              disabled={isLoading}
              name="adminPhone"
              placeholder="Enter your work phone"
              required
              type="tel"
              value={partnerState?.adminPhone?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Mobile (required for authentication)
            </span>

            <TextInput
              autocomplete="off"
              color={partnerState?.adminAuthMobile?.error ? "failure" : "gray"}
              disabled={isLoading}
              name="adminAuthMobile"
              placeholder="Enter your mobile for authentication"
              required
              type="tel"
              value={partnerState?.adminAuthMobile?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>
        </div>

        <div className="flex flex-col items-center gap-4 tablet:flex-row tablet:justify-end">
          <BackButton isLoading={isLoading} onClick={handlePrevStep}>
            Back
          </BackButton>

          <SubmitButton variant="small" type="submit" isLoading={isLoading}>
            Continue
          </SubmitButton>
        </div>
      </form>
    </>
  );
}
