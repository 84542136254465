import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { delay } from "./all";

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      const top = Math.abs(parseInt(document.body.style.top?.substring(-2)));

      if (!!top) {
        return;
      }

      setScrollPosition(window.scrollY);
    };

    document.addEventListener("scroll", updatePosition);

    updatePosition();

    return () => document.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const scrollToElement = (id) => {
  const element = document.getElementById(id);
  window.scrollTo({ top: element.offsetTop, behavior: "smooth" });
};

export const scrollToElementSmoothly = async (id, duration) => {
  const target = document.getElementById(id);
  if (target) {
    const targetPos = target.offsetTop - 70; // Minus 70 for navbar
    const startPos = window.scrollY;
    const distance = targetPos - startPos;
    let startTime = 0;

    const animation = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = currentTime - startTime;
      const easing = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
      const newPos = easing(progress / duration) * distance + startPos;
      window.scrollTo(0, newPos);
      if (progress < duration) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  } else {
    await delay(100);
    scrollToElementSmoothly(id, duration);
  }
};

export const ScrollToHashElement = (duration = 500) => {
  const { state } = useLocation();

  useEffect(() => {
    if (!!state?.hash) {
      console.log(state.hash);
      scrollToElementSmoothly(state?.hash, duration);
    }
  }, [state]);

  return null;
};
