import { ButtonWithRef, TextInput } from "components";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import {
  filterNumberAndSpaceKeyPress,
  filterNumberAndSpacePaste,
  handleKeyDownSubmission,
} from "services";

export function PartnerRegisterVerify({
  phone,
  handleInput,
  verifyPhoneNumber,
  err,
  submitRef,
}) {
  return (
    <>
      <div className="input-form flex w-full flex-col gap-4 tablet:mt-10">
        <div className="subtitle text-[14px] leading-[21px] text-gray-600">
          To get started, enter your mobile number.
        </div>

        {!!err?.msg && (
          <div className="text-[14px] font-medium text-red-600">{err.msg}</div>
        )}

        <TextInput
          id="MOBILE"
          name="mobile"
          type="tel"
          icon={HiOutlineDeviceMobile}
          placeholder="Enter your mobile number"
          required
          value={phone}
          onChange={handleInput}
          onKeyPress={filterNumberAndSpaceKeyPress}
          onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
          onPaste={filterNumberAndSpacePaste}
          //   color={!!abnSearchErr ? "failure" : "gray"}
        />

        <div className="flex justify-end">
          <ButtonWithRef
            variant="small"
            ref={submitRef}
            type="submit"
            onClick={verifyPhoneNumber}
          >
            Verify
          </ButtonWithRef>
        </div>
      </div>
    </>
  );
}
