import { Navigate, useParams } from "react-router-dom";
import { ROUTES } from "variables";

const CODES = {
  "2je3bzQ": "https://www.cybercert.ai/en-au/ref/X7gH4vR9pZ",
  "3be3bzB":
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/CSCAU_CyberCert+Overview.pdf",
  "1ct7bU":
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/CyberCertPartnerProgram_Huntress.pdf",
  "6fkm9bx":
    "https://cscau-public.s3.ap-southeast-2.amazonaws.com/CyberCertPartnerProgram_SentinelOne.pdf",
};

export function QRPage() {
  const params = useParams();

  const redirect = CODES?.[params.code];

  return redirect ? (
    (window.location = redirect)
  ) : (
    <Navigate to={`/${ROUTES[404]}`} />
  );
}
