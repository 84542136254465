import { useRef, useState } from "react";

import { ButtonWithRef, TextInput } from "components";
import {
  HiCheckCircle,
  HiOfficeBuilding,
  HiOutlineOfficeBuilding,
  HiRefresh,
} from "react-icons/hi";
import { handleKeyDownSubmission, openZEWidget } from "services";
import {
  filterNumberAndSpaceKeyPress,
  filterNumberAndSpacePaste,
} from "services/utils/input-validation";

export function RegisterFormSearchABN({
  registerState,
  handleInput,
  search,
  abnRegistered,
  abnSearchRes,
  validateContinue,
  canContinue,
  abnSearchErr,
  resetSearch,
}) {
  const [loading, setLoading] = useState(false);
  const submitRef = useRef(null);

  const submitSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    await search(registerState.abn);

    setLoading(false);
  };

  return (
    <div className="input-form flex flex-col gap-[16px]">
      <div className="flex flex-col items-start gap-[51px] self-stretch">
        <div className="title w-full align-middle text-[38px] font-semibold text-black laptop:h-[53px] laptop:w-[384px]">
          Enter your ABN
        </div>

        <div className="flex flex-col gap-4 self-stretch">
          {abnRegistered ? (
            <>
              <div className="text-[14px] text-gray-600">
                This organisation has already been claimed
              </div>

              <div className="lookup-details rounded border-[1px] border-red-600 bg-red-50 p-[20px]">
                <div className="mb-[16px] flex flex-row items-center justify-start gap-[16px]">
                  <HiOutlineOfficeBuilding className="font-bold text-gray-500" />{" "}
                  <span className="text-[14px] text-gray-600">
                    Organisation details
                  </span>
                </div>

                <div className="org-name mb-[12px] flex flex-row items-center justify-start gap-[8px]">
                  <span className="text-xl font-bold capitalize text-gray-900">
                    {abnSearchRes?.name}
                  </span>
                </div>

                <div className="org-abn">
                  <span className="font-bold">ABN</span> {abnSearchRes?.ABN}
                </div>

                <div className="org-location">
                  <span className="font-bold">Location</span>{" "}
                  {abnSearchRes?.addressState}, {abnSearchRes?.addressPostcode}
                </div>
              </div>

              <div className="text-[14px] font-semibold text-gray-900">
                <span
                  className="cursor-pointer text-blue-600 outline-none hover:underline focus:underline"
                  onClick={resetSearch}
                  tabIndex={0}
                >
                  Search Again
                </span>{" "}
                <HiRefresh className="inline-block text-blue-600" />
              </div>
            </>
          ) : (
            <>
              {!!abnSearchRes ? (
                <>
                  <div className="text-[14px] text-gray-600">
                    Is this your organisation? Check the details and proceed
                  </div>

                  <div className="lookup-details rounded border-[1px] border-green-600 bg-green-50 p-[20px]">
                    <div className="mb-[16px] flex flex-row items-center justify-start gap-[16px]">
                      <HiOutlineOfficeBuilding className="font-bold text-gray-500" />{" "}
                      <span className="text-[14px] text-gray-600">
                        Organisation details
                      </span>
                    </div>

                    <div className="org-name mb-[12px] flex flex-row items-center justify-start gap-[8px]">
                      <span className="text-xl font-bold capitalize text-gray-900">
                        {abnSearchRes.name}
                      </span>{" "}
                      <HiCheckCircle className="h-5 w-5 text-green-500" />
                    </div>

                    <div className="org-abn">
                      <span className="font-bold">ABN</span> {abnSearchRes.ABN}
                    </div>

                    <div className="org-location">
                      <span className="font-bold">Location</span>{" "}
                      {abnSearchRes.addressState},{" "}
                      {abnSearchRes.addressPostcode}
                    </div>
                  </div>

                  <div className="text-[14px] text-gray-900">
                    If not,{" "}
                    <span
                      className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                      onClick={resetSearch}
                      tabIndex={0}
                    >
                      Search Again
                    </span>{" "}
                    <HiRefresh className="inline-block text-blue-600" />
                  </div>
                </>
              ) : (
                <>
                  <div className="subtitle text-[14px] leading-normal text-gray-600">
                    Claim your organisation
                  </div>

                  {!!abnSearchErr && (
                    <>
                      <div className="font-medium text-red-600">
                        ABN is invalid. Please try again.
                      </div>
                    </>
                  )}

                  <TextInput
                    id="ABN"
                    name="abn"
                    type="text"
                    icon={HiOfficeBuilding}
                    placeholder="Enter your ABN"
                    required
                    onChange={handleInput}
                    onKeyPress={filterNumberAndSpaceKeyPress}
                    onKeyDown={(event) =>
                      handleKeyDownSubmission(event, submitRef)
                    }
                    onPaste={filterNumberAndSpacePaste}
                    color={!!abnSearchErr ? "failure" : "gray"}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>

      {abnRegistered ? (
        <>
          <div className="text-[14px] text-gray-900">
            Cannot find your organisation or it's been claimed?{" "}
            <span
              onClick={openZEWidget}
              className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
              tabIndex={0}
            >
              {" "}
              Get Help
            </span>
          </div>
        </>
      ) : (
        <>
          {!!abnSearchRes ? (
            <>
              <ButtonWithRef
                ref={submitRef}
                type="submit"
                onClick={validateContinue}
                disabled={!canContinue}
              >
                Continue
              </ButtonWithRef>

              <div className="text-[14px] text-gray-900">
                Cannot find your organisation or it's been claimed?{" "}
                <span
                  onClick={openZEWidget}
                  className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                  tabIndex={0}
                >
                  {" "}
                  Get Help
                </span>
              </div>
            </>
          ) : (
            <>
              <ButtonWithRef
                ref={submitRef}
                type="submit"
                disabled={loading}
                setLoading={setLoading}
                onClick={submitSearch}
              >
                <div className="flex items-center justify-center gap-[8px]">
                  Search ABN
                </div>
              </ButtonWithRef>

              <div className="text-[14px] text-gray-900">
                Can’t find your ABN?{" "}
                <span
                  onClick={openZEWidget}
                  className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                  tabIndex={0}
                >
                  {" "}
                  Get Help
                </span>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
