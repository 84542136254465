import { useRef, useState } from "react";
import { verifyPartnerAccount } from "api";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import { checkMobileNumber } from "services";
import { PartnerRegisterConfirm } from "./partner-register-confirm";
import { PartnerRegisterVerify } from "./partner-register-verify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "variables";
import { encode as base64_encode } from "base-64";

export function PartnerRegister() {
  const [partner, setPartner] = useState();
  const [invites, setInvites] = useState();
  const [step, setStep] = useState("verify"); // verify / confirm / register
  const [phone, setPhone] = useState("");
  const [err, setErr] = useState(null);
  const navigate = useNavigate();

  const submitRef = useRef();

  const handleInput = (event) => {
    setErr(null);
    setPhone(event?.target?.value);
  };

  const verifyPhoneNumber = async (event) => {
    event.preventDefault();

    // Check mobile number is valid
    if (!checkMobileNumber(phone)) {
      setErr({
        type: "mobile",
        msg: "Please enter a valid mobile number",
      });
      return;
    }

    // Check mobile number belongs to a partner account
    const verify = await verifyPartnerAccount({ phone });

    if (verify?.result === true) {
      setPartner({
        ...verify?.partner,
        entityName: verify?.organisation?.name,
        entityLocation: `${verify?.organisation?.addressState}, ${verify?.organisation?.addressPostcode}`,
      });
      setInvites(
        verify?.userInvites.map((invite) => ({
          ...invite,
          location: `${invite?.organisation?.addressState}, ${invite?.organisation?.addressPostcode}`,
        }))
      );
      setStep("confirm");
    } else {
      setErr({
        type: "mobile",
        msg: "No pending invites or registration for the specified mobile number",
      });
    }
  };

  const resetVerifyStep = () => {
    setStep("verify");
    setPhone("");
    setPartner(null);
    setInvites(null);
  };

  const confirmRegister = ({ partner, invites }) => {
    if (!!partner || invites?.length > 0) {
      navigate(
        `${ROUTES.ROOT}?c=${encodeURIComponent(
          base64_encode(
            JSON.stringify({
              isPartnerClaim: true,
              ABN: partner?.ABN,
              email: partner?.email,
              firstName: partner?.firstName,
              lastName: partner?.lastName,
              invite: invites[0],
            })
          )
        )}`
      );
    } else {
      setErr({
        msg: "Please select one organisation to join.",
      });
    }
  };

  return (
    <>
      <div className="content-body">
        <div className="form-container flex flex-col gap-8 tablet:flex-row">
          <div className="flex w-full flex-col justify-between gap-8 tablet:gap-0">
            <div className="flex flex-col gap-8">
              <div className="title text-[38px] font-semibold text-black">
                Accept Invitation
              </div>

              <p className="flex w-full text-sm leading-normal text-gray-600 tablet:w-[70%]">
                Your organisation has been invited to register your CyberCert
                CAP Partner Account.
              </p>

              <div className="flex flex-col gap-2">
                <div className="pb-1 text-sm leading-normal text-gray-600 tablet:w-[70%]">
                  Sign in to your account
                </div>

                {step === "confirm" && (
                  <div className="flex flex-row items-center gap-2">
                    <HiOutlineDeviceMobile className="text-icons h-5 w-5" />

                    <span className="">{phone}</span>

                    <button
                      className="text-link text-sm"
                      onClick={resetVerifyStep}
                    >
                      Change
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {
            {
              verify: (
                <>
                  <PartnerRegisterVerify
                    phone={phone}
                    handleInput={handleInput}
                    verifyPhoneNumber={verifyPhoneNumber}
                    err={err}
                    submitRef={submitRef}
                  />
                </>
              ),
              confirm: (
                <>
                  <PartnerRegisterConfirm
                    err={err}
                    submitRef={submitRef}
                    partner={partner}
                    invites={invites}
                    confirmRegister={confirmRegister}
                  />
                </>
              ),
            }[step]
          }
        </div>
      </div>
    </>
  );
}
