import { useRef, useState } from "react";

import {
  ButtonWithRef,
  CheckboxInput,
  PasswordInput,
  PasswordStrength,
  TextInput,
} from "components";
import { Label } from "flowbite-react";
import { FcGoogle } from "react-icons/fc";
import {
  HiChatAlt2,
  HiCheckCircle,
  HiLockClosed,
  HiMail,
  HiUser,
} from "react-icons/hi";
import {
  handleKeyDownInput,
  handleKeyDownSubmission,
  openZEWidget,
  validateEmail,
} from "services";
import { ROUTES } from "variables";

export function RegisterFormClaim({
  registerState,
  isPartnerClaim,
  handleInput,
  validateContinue,
  pwdStrengthRef,
  validateSSO,
  registerFB,
  registerGG,
  abnSearchRes,
  resetSearch,
  loading,
  title = "Claim your account",
  abnTitle = "Is this your organisation?",
  invite,
}) {
  const [err, setErr] = useState();
  const submitRef = useRef(null);

  const registerWithEmail = async () => {
    setErr(await validateContinue());
  };

  const registerSSO = async (ssoFn) => {
    setErr(await validateSSO(ssoFn));
  };

  const handleEmailInput = (event) => {
    if (!validateEmail(event.target.value)) {
      setErr({
        type: "email",
        msg: "Work email address is invalid.",
      });
    } else {
      setErr(null);
    }

    handleInput(event);
  };

  const handlePasswordInput = (event) => {
    if (
      !!registerState?.cPassword &&
      !!event?.target?.value &&
      event?.target?.value !== registerState?.cPassword
    ) {
      setErr({
        type: "match",
        message: "Passwords do not match.",
      });
    } else {
      setErr(null);
    }

    handleInput(event);
  };

  const handleConfirmPasswordInput = (event) => {
    if (
      !!registerState?.password &&
      !!event?.target?.value &&
      event?.target?.value !== registerState?.password
    ) {
      setErr({
        type: "match",
        message: "Passwords do not match.",
      });
    } else {
      setErr(null);
    }

    handleInput(event);
  };

  return (
    <div className="input-form flex w-full flex-col gap-8 tablet:flex-row tablet:justify-between tablet:gap-32">
      <div className="flex w-full flex-col items-start gap-8">
        <div className="title text-[38px] font-semibold text-black">
          {title}
        </div>

        <div className="flex h-full flex-col justify-between gap-8 tablet:gap-16">
          <div className="flex flex-col gap-4">
            {abnSearchRes?.ABN && (
              <div className="org-info">
                <div className="pb-8 text-sm leading-normal text-gray-600">
                  {abnTitle}
                </div>

                <div className="org-name flex flex-row items-center justify-start gap-[8px]">
                  <span className="text-xl font-bold capitalize text-gray-900">
                    {abnSearchRes?.name}
                  </span>{" "}
                  <HiCheckCircle className="h-5 w-5 text-green-500" />
                </div>

                <div className="org-abn">
                  <span className="font-bold">ABN</span> {abnSearchRes?.ABN}
                </div>

                <div className="mb-[12px]"></div>

                <div className="text-[14px] text-gray-900">
                  If not,{" "}
                  {/* <span
                    className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                    onClick={resetSearch}
                    tabIndex={0}
                  >
                    Search Again
                  </span>{" "} */}
                  <span
                    className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                    onClick={openZEWidget}
                    tabIndex={0}
                  >
                    Get Help
                  </span>{" "}
                  <HiChatAlt2 className="inline-block text-blue-600" />
                </div>
              </div>
            )}

            {!!invite?.ABN && (
              <div className="flex flex-col gap-2">
                <div className="text-sm leading-normal text-gray-600">
                  {abnTitle}
                </div>
                <div className="org-info">
                  <div className="org-name flex flex-row items-center justify-start gap-[8px]">
                    <span className="text-xl font-bold capitalize text-gray-900">
                      {invite?.organisation?.name}
                    </span>{" "}
                    <HiCheckCircle className="h-5 w-5 text-green-500" />
                  </div>

                  <div className="org-abn">
                    <span className="font-bold">ABN</span> {invite?.ABN}
                  </div>

                  <div className="mb-[12px]"></div>

                  <div className="text-[14px] text-gray-900">
                    If not,{" "}
                    {/* <span
                        className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                        onClick={resetSearch}
                        tabIndex={0}
                      >
                        Search Again
                      </span>{" "} */}
                    <span
                      className="cursor-pointer font-medium text-blue-600 outline-none hover:underline focus:underline"
                      onClick={openZEWidget}
                      tabIndex={0}
                    >
                      Get Help
                    </span>{" "}
                    <HiChatAlt2 className="inline-block text-blue-600" />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex w-full flex-row items-start gap-4 tablet:w-[85%]">
            <div className="mt-[-2px]">
              <CheckboxInput
                checked={registerState?.accept}
                id="accept"
                name="accept"
                onChange={(event) => {
                  handleInput({
                    target: {
                      name: "accept",
                      value: event.target?.checked,
                    },
                  });
                }}
                onKeyDown={handleKeyDownInput}
              />
            </div>

            <Label
              htmlFor="accept"
              className="text-[14px] font-normal text-gray-600"
            >
              By claiming this organisation, you agree to CyberCert’s{" "}
              <a href={ROUTES.TERMS} target="_blank" rel="noreferrer">
                <span className="cursor-pointer text-blue-600 underline">
                  Terms of use
                </span>
              </a>{" "}
              and{" "}
              <a href={ROUTES.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                <span className="cursor-pointer text-blue-600 underline">
                  Privacy Policy
                </span>
              </a>
            </Label>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 tablet:mt-10">
        <div className={`mt-[16px] flex flex-col gap-[8px] laptop:flex-row`}>
          <ButtonWithRef
            className="w-full"
            variant="social"
            onClick={() => registerSSO(registerGG)}
          >
            <FcGoogle className="h-5 w-5" />
            Sign in with Google
          </ButtonWithRef>

          {/* <Button variant="social" className="w-full">
              <img
                src={MicrosoftLogo}
                className="h-4 w-4"
                alt="Microsoft Logo"
              />
              Register using Microsoft
            </Button> */}

          {/* <Button variant="social" onClick={() => registerSSO(registerFB)}>
              <BsFacebook className="h-5 w-5 text-[#1877F2]" />
              Claim with Facebook
            </Button> */}
        </div>

        <div
          className={`
            separator flex items-center text-base font-medium text-gray-500
            before:mr-[24px] before:flex-1 before:bg-gray-400 before:py-[0.5px] before:content-['']
            after:ml-[24px] after:flex-1 after:bg-gray-400 after:py-[0.5px] after:content-['']
          `}
        >
          or
        </div>

        <div className="subtitle text-[14px] leading-normal text-gray-600">
          Sign in with work email
        </div>

        {!!err?.message && (
          <div className="text-[14px] font-medium text-red-600">
            {err.message}
          </div>
        )}

        <div className="flex flex-col gap-2">
          <TextInput
            autoComplete={"email"}
            id="email"
            className="border-none"
            type="email"
            name="email"
            placeholder="Work email address"
            value={registerState?.email}
            disabled={isPartnerClaim}
            icon={HiMail}
            required
            shadow
            onChange={handleEmailInput}
            onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
            color={err?.type === "email" ? "failure" : "gray"}
          />

          {!isPartnerClaim && (
            <div className="grid grid-cols-1 gap-2 tablet:grid-cols-2">
              <TextInput
                id="firstName"
                type="text"
                name="firstName"
                placeholder="First name"
                icon={HiUser}
                required
                shadow
                onChange={handleInput}
                onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={err?.type === "firstName" ? "failure" : "gray"}
              />

              <TextInput
                id="lastName"
                type="text"
                name="lastName"
                placeholder="Last name"
                icon={HiUser}
                required
                shadow
                onChange={handleInput}
                onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={err?.type === "lastName" ? "failure" : "gray"}
              />
            </div>
          )}

          <div className="grid grid-cols-1 gap-2 tablet:grid-cols-2">
            <PasswordInput
              autoComplete={"current-password"}
              id="password"
              type="password"
              name="password"
              placeholder="Password"
              icon={HiLockClosed}
              required
              shadow
              onChange={handlePasswordInput}
              onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
              color={
                err?.type === "password" || err?.type === "match"
                  ? "failure"
                  : "gray"
              }
            />

            <PasswordInput
              autoComplete={"current-password"}
              id="cPassword"
              type="password"
              name="cPassword"
              placeholder="Confirm password"
              icon={HiLockClosed}
              required
              shadow
              onChange={handleConfirmPasswordInput}
              onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
              color={
                err?.type === "cPassword" || err?.type === "match"
                  ? "failure"
                  : "gray"
              }
            />
          </div>
        </div>

        {!!registerState.password && <PasswordStrength ref={pwdStrengthRef} />}

        <div className="flex justify-end">
          <ButtonWithRef
            variant="small"
            ref={submitRef}
            type="submit"
            onClick={registerWithEmail}
          >
            Continue
          </ButtonWithRef>
        </div>
      </div>

      {/* <div className="text-[14px] text-gray-900">
        Don't have an account yet?{" "}
        <span className="text-sm font-medium text-primary">
          <Link to={ROUTES.CLAIM}>Claim your organisation</Link>
        </span>
      </div> */}
    </div>
  );
}
