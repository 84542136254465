import { WrapperItems } from "components/wrapper/items";
import { Footer } from "layouts/footer";
import { Header } from "layouts/header";

export function LegalLayoutWrapper({ children }) {
  return (
    <>
      <WrapperItems />

      <Header />

      {children}

      <Footer />
    </>
  );
}
