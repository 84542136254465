import { useEffect, useRef, useState } from "react";

import { encode as base64_encode } from "base-64";
import { ButtonWithRef } from "components/buttons";
import { FormCard } from "components/cards";
import { PasswordInput, TextInput } from "components/input";
import { useLoadingState } from "middleware";
import queryString from "query-string";
import { FcGoogle } from "react-icons/fc";
import { HiLockClosed, HiUser } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import {
  AuthService,
  handleKeyDownInput,
  handleKeyDownSubmission,
} from "services";
import { ROUTES } from "variables";

// import { MicrosoftLogo } from "assets";

const INITIAL_LOGIN_STATE = {
  email: "",
  password: "",
  remember: false,
};

export function PartnerLoginForm() {
  const { setLoading } = useLoadingState();

  const [loginState, setLoginState] = useState(INITIAL_LOGIN_STATE);
  const [err, setErr] = useState();
  const [successUrl, setSuccessUrl] = useState();

  const location = useLocation();
  const loginRef = useRef(null);

  const handleInput = (event) => {
    if (event.target.name === "remember") {
      setLoginState({
        ...loginState,
        remember: event.target.checked,
      });
    } else {
      setLoginState({
        ...loginState,
        [event.target.name]: event.target.value,
      });
    }
  };

  const login = async () => {
    setLoading(true);
    try {
      const { email, password, remember } = loginState;
      await AuthService.signIn({
        email,
        password,
        remember,
        signinCallback: () => {},
        confirmCallback: () => {
          window.location.href = `${ROUTES.ROOT}?c=${encodeURIComponent(
            base64_encode(
              JSON.stringify({
                confirm: true,
                email,
                resend: true,
              })
            )
          )}`;
        },
        successUrl,
      });
      setErr(null);
    } catch (e) {
      setErr({
        message: e.message,
        code: "login",
      });
      setLoading(false);
    }
  };

  const loginGG = async () => {
    await AuthService.signinWithGoogle({ successUrl });
  };

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (!!params.success_url) {
      setSuccessUrl(params.success_url);
    }

    if (!!params["sso_fail"] || !!params["email_fail"]) {
      setErr({
        code: "sso",
        message: (
          <>
            <p>The username or password for the specific account is invalid.</p>
            <p>
              Not a partner? Sign in to cybercert{" "}
              <a href="/login" className="pointer underline">
                here
              </a>
              .
            </p>
          </>
        ),
      });
    }
  }, [location.search]);

  return (
    <>
      <FormCard title="Welcome">
        {/* <div className="subtitle text-[14px] leading-[21px] text-gray-600">
          Login via SSO
        </div> */}
        <div className="input-form flex flex-col gap-[16px]">
          <div className={`flex w-full flex-col gap-[8px] laptop:flex-row`}>
            <ButtonWithRef
              className="w-full"
              variant="social"
              onClick={loginGG}
            >
              <FcGoogle className="h-5 w-5" />
              Sign in with Google
            </ButtonWithRef>

            {/* <Button className="w-full" variant="social">
            <img src={MicrosoftLogo} className="h-4 w-4" alt="Microsoft Logo" />
            Sign in with Microsoft
          </Button> */}
          </div>

          <div
            className={`
              separator flex items-center text-base text-gray-500
              before:mr-[24px] before:flex-1 before:bg-gray-400 before:py-[0.5px] before:content-['']
              after:ml-[24px] after:flex-1 after:bg-gray-400 after:py-[0.5px] after:content-['']
            `}
          >
            or
          </div>

          <div className="subtitle text-[14px] leading-[21px] text-gray-600">
            Enter email and password to login
          </div>

          {!!err && (
            <div className="text-[14px] font-medium text-red-600">
              {err.message}
            </div>
          )}

          <div className="flex flex-col gap-2">
            <TextInput
              id="email"
              type="email"
              name="email"
              placeholder="Work Email Address"
              icon={HiUser}
              required
              onChange={handleInput}
              onKeyDown={(e) => handleKeyDownSubmission(e, loginRef)}
              color={
                err?.code === "sso" ||
                err?.code === "email" ||
                err?.code === "login"
                  ? "failure"
                  : "gray"
              }
            />

            <PasswordInput
              id="password"
              type="password"
              name="password"
              placeholder="Password"
              icon={HiLockClosed}
              required
              onChange={handleInput}
              onKeyDown={(e) => handleKeyDownSubmission(e, loginRef)}
              color={
                err?.code === "sso" ||
                err?.code === "password" ||
                err?.code === "login"
                  ? "failure"
                  : "gray"
              }
            />
          </div>

          <div className="flex flex-col justify-start gap-4 tablet:flex-row tablet:items-center tablet:justify-between tablet:gap-16">
            <div className="mt-2 flex flex-row justify-between gap-4 tablet:items-center tablet:justify-start">
              {/* <div className="option-remember flex flex-row items-center gap-[8px] whitespace-nowrap">
                <CheckboxInput
                  id="remember"
                  name="remember"
                  onChange={handleInput}
                  onKeyDown={(event) => handleKeyDownInput(event)}
                />
                <Label
                  htmlFor="remember"
                  className="text-sm font-normal leading-none text-gray-600"
                >
                  Remember me
                </Label>
              </div> */}

              <div
                className="cursor-pointer whitespace-nowrap text-sm font-medium text-primary outline-none hover:underline focus:underline"
                onClick={() => {
                  window.location.href = ROUTES.RESET;
                }}
                onKeyDown={handleKeyDownInput}
                tabIndex={0}
              >
                Forgot password?
              </div>
            </div>

            <ButtonWithRef
              variant="small"
              ref={loginRef}
              type="submit"
              onClick={login}
            >
              Login
            </ButtonWithRef>
          </div>

          {/* <div
            className={`
            no-account flex flex-col gap-[2px]
            laptop:flex-row laptop:gap-[4px]
          `}
          >
            <div className="text-sm font-medium text-gray-900">
              Don't have an account yet?
            </div>
  
            <div className="text-sm font-medium text-primary">
              <Link to={ROUTES.CLAIM}>Claim your organisation</Link>
            </div>
          </div> */}
        </div>
      </FormCard>
    </>
  );
}
