import { ModalTheme } from "assets";
import { Flowbite, Modal } from "flowbite-react";

export function PopupModal({
  show,
  size = "md",
  position = "center",
  onClose = () => {},
  children,
  dismissible = true,
}) {
  return (
    <Flowbite
      theme={{
        theme: {
          modal: ModalTheme,
        },
      }}
    >
      <Modal
        theme={ModalTheme}
        dismissible={dismissible}
        show={show}
        onClose={onClose}
        size={size}
        position={position}
      >
        {children}
      </Modal>
    </Flowbite>
  );
}
